import React, { useState } from 'react';
import DropdownFilter from '../elements/DropdownFilter';

import './styles.scss';

export default function CardHome({ title }) {
  const [hide, setHide] = useState(true);
  const [period, setPeriod] = useState({ value: 'hoje', label: 'Hoje' });

  return (
    <div className="cardHome">
      <div className="card-home-header">
        <h5>
          <i className="fas fa-chart-line"></i> {title}
        </h5>
        <DropdownFilter
          value={period}
          onValueChange={(selectedPeriod) => setPeriod(selectedPeriod)}
        />
      </div>
      <hr />
      <div className="card-home-body">
        <div className="payments">
          <h6>O que eu vendi</h6>
          <i
            className="fas fa-eye hide-button"
            onClick={() => setHide(!hide)}
          ></i>
        </div>

        <div className="net-total">
          <span className="key">Venda Bruta</span>
          <span className={!hide ? 'value' : 'hide-value'}>
            {!hide && 'R$ 34957,63'}
          </span>
        </div>

        <div className="net-sale">
          <span className="key">Venda líquida</span>
          <span className={!hide ? 'value' : 'hide-value'}>
            {!hide && 'R$ 27456,63'}
          </span>
        </div>

        <div className="tariffsFees">
          <span className="key">Tarifas e taxas</span>
          <span className={!hide ? 'value' : 'hide-value'}>
            {!hide && 'R$ 7501,02'}
          </span>
        </div>

        <span className="obs">*Valores sujeitos a alteração</span>
      </div>
    </div>
  );
}
