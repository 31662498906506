import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Formik, ErrorMessage, Form } from 'formik';
import Select from 'react-select';
import { format } from 'date-fns';

import DropdownFilter from '../elements/DropdownFilter';

import validationSchema from './validationSchema';

export default function ModalExport({
  modalVisible,
  modalToggle,
  action = () => {},
}) {
  const [period, setPeriod] = useState({
    value: format(new Date(), 'yyyy-MM-dd'),
    label: 'Hoje',
  });

  const initialValues = {
    tipoExtrato: null,
    tipoApresentacao: null,
    formatoArquivo: null,
  };

  const tiposExtrato = [
    {
      label: 'Pagamentos',
      value: 1,
    },
    {
      label: 'Antecipações',
      value: 2,
    },
  ];

  const tiposApresentacao = [
    {
      label: 'Consolidado',
      value: 1,
    },
    {
      label: 'Detalhado',
      value: 2,
    },
    {
      label: 'Consolidado + Detalhado',
      value: 3,
    },
  ];

  const formatosArquivo = [
    {
      label: 'CSV',
      value: 1,
    },
    {
      label: 'Excel',
      value: 2,
    },
    {
      label: 'PDF',
      value: 3,
    },
  ];

  return (
    <>
      <Modal
        isOpen={modalVisible}
        toggle={modalToggle}
        className="modal-dialog modal-lg"
        onClosed={action}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={async (data) => {
            const { tipoApresentacao, tipoExtrato, formatoArquivo } = data;
            const dataInicio = period.value.split(' ')[0];
            const dataFim = period.value.split(' ')[1];

            const payload = {
              tipoApresentacao,
              tipoExtrato,
              formatoArquivo,
              dataInicio,
              dataFim,
            };

            console.log('formData', payload);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <ModalHeader toggle={modalToggle}>Exportar</ModalHeader>
              <ModalBody className="modal-content">
                <div className="row justify-content-center">
                  <div className="col-3">
                    <DropdownFilter
                      value={period}
                      onValueChange={(selectedPeriod) =>
                        setPeriod(selectedPeriod)
                      }
                    />
                  </div>
                </div>

                <div className="modal-body">
                  <div className="card-body">
                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div className="form-group row">
                          <label className="col-4 col-form-label text-right">
                            Tipo Extrato
                          </label>
                          <div className="col-8">
                            <Select
                              name="tipoExtrato"
                              value={tiposExtrato.find(
                                (tipoExtrato) =>
                                  tipoExtrato.value === values.tipoExtrato,
                              )}
                              onChange={(e) =>
                                setFieldValue('tipoExtrato', e.value)
                              }
                              placeholder="Selecione uma opção"
                              options={tiposExtrato}
                            />
                            <ErrorMessage
                              name="tipoExtrato"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-4 col-form-label text-right">
                            Tipo Apresentação
                          </label>
                          <div className="col-8">
                            <Select
                              name="tipoApresentacao"
                              value={tiposApresentacao.find(
                                (tipoApresentacao) =>
                                  tipoApresentacao.value ===
                                  values.tipoApresentacao,
                              )}
                              onChange={(e) =>
                                setFieldValue('tipoApresentacao', e.value)
                              }
                              placeholder="Selecione uma opção"
                              options={tiposApresentacao}
                            />
                            <ErrorMessage
                              name="tipoApresentacao"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-4 col-form-label text-right">
                            Formato do arquivo
                          </label>
                          <div className="col-8">
                            <Select
                              name="formatoArquivo"
                              value={formatosArquivo.find(
                                (formatoArquivo) =>
                                  formatoArquivo.value ===
                                  values.formatoArquivo,
                              )}
                              onChange={(e) =>
                                setFieldValue('formatoArquivo', e.value)
                              }
                              placeholder="Selecione uma opção"
                              options={formatosArquivo}
                            />
                            <ErrorMessage
                              name="formatoArquivo"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>

              <ModalFooter>
                <button className="btn btn-primary" type="submit">
                  Exportar
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={modalToggle}
                >
                  Cancelar
                </button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
