const initialValues = {
  nomeFantasia: '',
  razaoSocial: '',
  email: '',
  celular: '',
  telefoneFixo: '',
  cnpj: '',
  chave: '',
  codigoBanco: '',
  tipoConta: '',
  agencia: '',
  conta: '',
  contaDig: '',
  corHexadecimal: '',
  usuario: {
    nome: '',
    email: '',
    celular: '',
    telefoneFixo: '',
    telefoneComercial: '',
    cpf: '',
    dataNascimento: '',
  },
  endereco: {
    cep: '',
    bairro: '',
    logradouro: '',
    estado: '',
    cidade: '',
    numero: '',
    complemento: '',
    pais: 'BRASIL',
  },
};

export default initialValues;
