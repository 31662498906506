/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { Context } from '../../context/AuthContext';
import SpinnerLoading from '../../components/SpinnerLogin';

import { cpfMask } from '../../helpers/Utils';
import { cpf } from 'cpf-cnpj-validator';

import InputMask from 'react-input-mask';

import { ReactComponent as EyeIcon } from '../../assets/svg/eye.svg';

import './styles.scss';

export default function ModalRecuperarSenha({
  inputCpf,
  setInputCpf,
  modalVisible,
  modalToggle,
  etapaTrocaSenha,
  setEtapaTrocaSenha,
}) {
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [cpfInvalido, setCpfInvalido] = useState(false);
  const [codigoInvalido, setCodigoInvalido] = useState(false);
  const [senhaInvalida, setSenhaInvalida] = useState(false);
  const [loading, setLoading] = useState(false);
  const [etapa, setEtapa] = useState(1);
  const [codigo, setCodigo] = useState(null);
  const [senha, setSenha] = useState(null);
  const [confirmarSenha, setConfirmarSenha] = useState(null);
  const [labelSubmit, setLabelSubmit] = useState('Enviar');
  const [senhasImcompativeis, setSenhasIncompativeis] = useState(false);
  const [celular, setCelular] = useState('');

  const [passVisible1, setPassVisible1] = useState(false);
  const [passVisible2, setPassVisible2] = useState(false);

  const { enviarCodigoSenha, validarCodigo, trocarSenha } = useContext(Context);

  useEffect(() => {
    if (etapaTrocaSenha) {
      setEtapa(etapaTrocaSenha);
    }
  }, [etapaTrocaSenha]);

  function handleChange(e) {
    setCpfInvalido(false);
    const { value } = e.target;

    setErrors({ ...errors, cpf: 'Digite seu CPF' });
    setInputCpf(cpfMask(value));
  }

  const handleChangeCodigo = (e) => {
    setCodigoInvalido(false);
    const { value } = e.target;

    setErrors({ ...errors, codigo: 'Digite o código' });
    setCodigo(value);
  };

  const handleChangePassword = (e) => {
    const { id, value } = e.target;
    setSenhaInvalida(false);

    switch (id) {
      case 'senha':
        setErrors({ ...errors, senha: 'Digite a nova senha' });
        setSenha(value);
        break;
      case 'confirmarSenha':
        if (senha !== value) {
          setSenhasIncompativeis(true);
        } else {
          setSenhasIncompativeis(false);
        }
        setErrors({ ...errors, confirmarSenha: 'Confirme a nova senha' });
        setConfirmarSenha(value);
        break;
      default:
    }
  };

  async function handleValidate(e, reenviarCodigo) {
    e.preventDefault();

    switch (reenviarCodigo || etapa) {
      case 0:
        modalToggle(!modalVisible);
        setLabelSubmit('Enviar');
        setEtapa(1);
        break;
      case 1:
        if (!inputCpf) {
          setTouched({ ...touched, cpf: true });
          setErrors({ ...errors, cpf: 'Digite seu CPF' });
        } else if (!cpf.isValid(inputCpf)) {
          setTouched({ ...touched, cpf: true });
          setErrors({ ...errors, cpf: 'CPF Inválido' });
          setCpfInvalido(true);
        } else {
          setLoading(true);

          const data = await enviarCodigoSenha(inputCpf).catch(() =>
            setLoading(false),
          );

          if (data?.sucesso) {
            setLabelSubmit('Validar Código');
            setEtapa(2);
            setCelular(data?.data);
          }
        }
        break;
      case 2:
        if (!codigo) {
          setTouched({ ...touched, codigo: true });
          setErrors({ ...errors, codigo: 'Digite o código' });
        } else {
          setLoading(true);
          const response = await validarCodigo(inputCpf, codigo).catch(() =>
            setLoading(false),
          );

          if (response?.sucesso) {
            setLabelSubmit('Alterar Senha');
            setEtapa(3);
          } else {
            setCodigoInvalido(true);
            setErrors({ ...errors, codigo: 'Código Inválido' });
          }
          setLoading(false);
        }

        break;

      case 3:
        if (!senha || !confirmarSenha) {
          setTouched({ ...touched, senha: true, confirmarSenha: true });
          setErrors({
            ...errors,
            senha: !senha && 'Digite a nova senha',
            confirmarSenha: !confirmarSenha && 'Confirme a nova senha',
          });

          return;
        }

        if (!senha.match(/^(?=.*\d)(?=.*[A-Z])([^\s]){8,}$/)) {
          setSenhaInvalida(true);
          setTouched({ ...touched, senha: true });
          setErrors({
            ...errors,
            senha:
              'Importante: A senha deve conter pelo menos 1 letra maiúscula, 1 número e 8 caracteres.',
          });
        } else if (senha !== confirmarSenha) {
          setSenhasIncompativeis(true);
        } else {
          setLoading(true);

          const data = await trocarSenha(inputCpf, senha).catch(() =>
            setLoading(false),
          );

          if (data?.sucesso) {
            setLabelSubmit('Fechar');
            setEtapaTrocaSenha(null);
            setEtapa(0);
          }
        }

        break;
      default:
    }

    setLoading(false);
  }

  function fluxoRecuperacaoSenha() {
    switch (etapa) {
      case 1:
        return (
          <>
            <div className="form-label-group mb-4">
              <input
                type="text"
                className={`form-control ${
                  (!inputCpf || cpfInvalido) &&
                  errors.cpf &&
                  touched.cpf &&
                  'required'
                }`}
                placeholder="CPF"
                id="cpfModal"
                value={inputCpf}
                onChange={handleChange}
                onFocus={() => setTouched({ ...touched, cpf: true })}
              />
              <label htmlFor="cpfModal">Digite seu CPF</label>
              <small>
                {(!inputCpf || cpfInvalido) && touched.cpf && errors.cpf}
              </small>
            </div>
          </>
        );
      case 2:
        return (
          <div className="form-label-group mb-4">
            <InputMask
              className={`form-control ${
                (!codigo || codigoInvalido) &&
                errors.codigo &&
                touched.codigo &&
                'required'
              }`}
              mask="99999"
              placeholder="Digite o código"
              id="codigo"
              value={codigo}
              onChange={handleChangeCodigo}
              onFocus={() => setTouched({ ...touched, codigo: true })}
            />

            <label htmlFor="codigo">Digite o código</label>
            <small>
              {(!codigo || codigoInvalido) && touched.codigo && errors.codigo}
            </small>
          </div>
        );
      case 3:
        return (
          <>
            <div className="pass-wrapper">
              <div className="form-label-group mb-4">
                <input
                  type={passVisible1 ? 'text' : 'password'}
                  className={`form-control ${
                    (!senha || senhaInvalida) &&
                    errors.senha &&
                    touched.senha &&
                    'required'
                  }`}
                  placeholder="Digite a nova senha"
                  id="senha"
                  value={senha}
                  onChange={handleChangePassword}
                  onFocus={() => setTouched({ ...touched, senha: true })}
                />
                <label htmlFor="senha">Digite a nova senha</label>
                <small>
                  {(!senha || senhaInvalida) && touched.senha && errors.senha}
                </small>
              </div>
              {senha && (
                <EyeIcon
                  role="button"
                  className="cursor-pointer eye-icon"
                  onClick={() => setPassVisible1(!passVisible1)}
                />
              )}
            </div>
            <div className="pass-wrapper">
              <div className="form-label-group mb-4">
                <input
                  type={passVisible2 ? 'text' : 'password'}
                  className={`form-control ${
                    !confirmarSenha &&
                    errors.confirmarSenha &&
                    touched.confirmarSenha &&
                    'required'
                  }`}
                  placeholder="Confirme a nova senha"
                  id="confirmarSenha"
                  value={confirmarSenha}
                  onChange={handleChangePassword}
                  onFocus={() =>
                    setTouched({ ...touched, confirmarSenha: true })
                  }
                />
                <label htmlFor="confirmarSenha">Confirme a nova senha</label>
                <small>
                  {!confirmarSenha &&
                    touched.confirmarSenha &&
                    errors.confirmarSenha}
                </small>
              </div>
              {confirmarSenha && (
                <EyeIcon
                  role="button"
                  className="cursor-pointer eye-icon"
                  onClick={() => setPassVisible2(!passVisible2)}
                />
              )}
            </div>
            {senhasImcompativeis && (
              <small className="erro-login mb-4">As senhas não coincidem</small>
            )}
          </>
        );
      default:
    }
  }

  return (
    <Modal
      isOpen={modalVisible}
      toggle={modalToggle}
      className="modal-dialog-centered modal-sm"
    >
      <ModalBody className="modal-content text-center">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />

        {etapaTrocaSenha ? (
          <h4 className="mb-4">
            Para a sua segurança crie sua senha personalizada.
          </h4>
        ) : (
          <h4 className="mb-4">Recuperar Senha</h4>
        )}

        {etapa === 0 && (
          <p>Senha alterada com sucesso! Faça login com a nova senha.</p>
        )}

        {etapa === 1 && (
          <p>
            Olá! Informe seu CPF no campo abaixo que enviaremos um código de
            confirmação para seu celular cadastrado, digite o mesmo no campo
            abaixo e altere sua senha.
          </p>
        )}

        {etapa === 2 && (
          <p>{`Informe o código recebido no celular com o final: ${celular}`}</p>
        )}

        <form className="text-start mb-3">
          {fluxoRecuperacaoSenha()}
          <button
            href="#"
            onClick={(e) => handleValidate(e)}
            className="btn btn-primary rounded-pill btn-login w-100 mb-2"
            alt="Enviar"
            disabled={loading}
          >
            {loading ? <SpinnerLoading /> : labelSubmit}
          </button>
        </form>
        {/* <!-- /form --> */}
        {etapa === 2 && (
          <small className="reennviar-codigo">
            Não recebeu o código?{' '}
            <a href="#" onClick={(e) => handleValidate(e, 1)} className="hover">
              Clique aqui para reenviar
            </a>
          </small>
        )}
      </ModalBody>
    </Modal>
  );
}
