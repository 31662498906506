/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Container from '../../components/elements/Container';

import PropostasProvider from '../../context/PropostaContext';

import PropostasList from './PropostasList';

export default function Propostas() {
  return (
    <Container>
      <PropostasProvider>
        <PropostasList />
      </PropostasProvider>
    </Container>
  );
}
