import { object, string, addMethod } from 'yup';
import { cpf } from 'cpf-cnpj-validator';

addMethod(string, 'validateCPF', function (errorMessage) {
  return this.test(`test-cpf`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      return cpf.isValid(value) || createError({ path, message: errorMessage });
    } else {
      return true;
    }
  });
});

addMethod(string, 'validateCellphone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      value = value.replace(/\D/g, '');

      const validateCellphone =
        value.substr(2, 1) === '9' &&
        !value.match(/^(\d)\1{9,10}$/) &&
        value.length === 11;

      return (
        !!validateCellphone || createError({ path, message: errorMessage })
      );
    } else {
      return true;
    }
  });
});

const validationSchema = object().shape({
  cpfCliente: string().validateCPF('CPF inválido').nullable(),
  email: string().email('E-mail inválido').nullable(),
  celular: string().validateCellphone('Celular inválido').nullable(),
  dataInicio: string().required('Data obrigatória').nullable(),
  dataFim: string().required('Data obrigatória').nullable(),
});

export default validationSchema;
