import React from 'react';
import logoDark from './img/logo-dark.png';

export default function Header() {
  return (
    <header className="wrapper bg-soft-primary">
      <nav className="navbar classic transparent navbar-expand-lg navbar-light">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-100">
            <a href="start.html">
              <img
                src={logoDark}
                srcSet={logoDark}
                alt=""
                style={{ width: 140 }}
              />
            </a>
          </div>
          <div className="navbar-collapse offcanvas-nav">
            <div className="offcanvas-header d-lg-none d-xl-none">
              <a href="start.html">
                <img
                  src="/img/logo-light.png"
                  srcSet="/img/logo-light@2x.png 2x"
                  alt="Voe Pay"
                />
              </a>
              <button
                type="button"
                className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                aria-label="Close"
              ></button>
            </div>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  A VoePay
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  Condições Exclusivas
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  Fale Conosco
                </a>
              </li>
            </ul>
            {/* <!-- /.navbar-nav --> */}
          </div>
          {/* <!-- /.navbar-collapse --> */}
          <div className="navbar-other ms-lg-4">
            <ul
              className="navbar-nav flex-row align-items-center ms-auto"
              data-sm-skip="true"
            >
              <li className="nav-item d-none d-md-block">
                <a href="#" className="btn btn-sm btn-dark rounded">
                  Quero ser Parceiro
                </a>
              </li>
              <li className="nav-item ms-lg-0">
                <div className="navbar-hamburger d-lg-none d-xl-none ms-auto">
                  <button
                    className="hamburger animate plain"
                    data-toggle="offcanvas-nav"
                  >
                    <span></span>
                  </button>
                </div>
              </li>
            </ul>
            {/* <!-- /.navbar-nav --> */}
          </div>
          {/* <!-- /.navbar-other --> */}
        </div>
        {/* <!-- /.container --> */}
      </nav>
      {/* <!-- /.navbar --> */}
    </header>
    /* <!-- /header --> */
  );
}
