import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import cep from 'cep-promise';
import Select from 'react-select';
import { SketchPicker } from 'react-color';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { v1 as uuidv1 } from 'uuid';
import { cadastrarParceiro } from '../../webReferences/userAPI';
import { getBancos } from '../../webReferences/bancoAPI';

import InputMask from 'react-input-mask';

import validationSchema from './validationSchema';
import initialValues from './initialValues';

import Container from '../../components/elements/Container';
import NavbarMenu from '../../components/elements/NavbarMenu';
import Footer from '../../components/elements/Footer';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';

import ptBR from 'date-fns/locale/pt-BR';
import './styles.scss';

registerLocale('pt-BR', ptBR);

const tiposConta = [
  {
    label: 'Conta Corrente',
    value: 1,
  },
  {
    label: 'Poupança',
    value: 2,
  },
];

export default function Cadastro() {
  const [loading, setLoading] = useState(false);
  const [bancos, setBancos] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('');
  const [showComponent, setShowComponent] = useState(false);
  const [chave, setChave] = useState('');

  useEffect(() => {
    function loadStyles() {
      return new Promise((resolve) => {
        resolve(
          import('../../assets/css/style.bundle.css'),
          import('../../assets/plugins/global/plugins.bundle.css'),
        );
      });
    }

    loadStyles().then(() => {
      setShowComponent(true);
    });

    setChave(uuidv1());
  }, []);

  useEffect(() => {
    async function loadBancos() {
      const ret = await getBancos();
      setBancos(
        ret?.data?.map((banco) => ({
          label: banco.descricao,
          value: banco.codigoFebraBan,
        })) || [],
      );
    }

    loadBancos();
  }, []);

  const handleCep = (e, form) => {
    const { setFieldTouched, setValues, setFieldError, values } = form;

    setFieldTouched('endereco.cep', true);

    let { value } = e.target;
    value = value.replace(/\D/g, '');

    cep(value)
      .then((cepData) => {
        setValues({
          ...values,
          endereco: {
            ...values.endereco,
            bairro: cepData.neighborhood,
            logradouro: cepData.street,
            estado: cepData.state,
            cidade: cepData.city,
          },
        });
      })
      .catch(() => {
        setFieldError('endereco.cep', 'CEP não encontrado');

        setValues({
          ...values,
          endereco: {
            ...values.endereco,
            bairro: '',
            logradouro: '',
            estado: '',
            cidade: '',
          },
        });
      });
  };

  const handleColorPicker = () => setDisplayColorPicker(!displayColorPicker);

  const handleChangeComplete = (color, setFieldValue) => {
    setColor(color.hex);
    setFieldValue('corHexadecimal', color.hex);
  };

  return (
    showComponent && (
      <>
        <NavbarMenu />
        <Container>
          <Overlay active={loading} marginTop={300}>
            <Card>
              <div className="card-body p-0">
                <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                  <div className="col-xl-12 col-xxl-7">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      validateOnBlur={false}
                      validateOnChange={true}
                      onSubmit={async (data) => {
                        setLoading(true);

                        const {
                          nomeFantasia,
                          razaoSocial,
                          celular,
                          telefoneFixo,
                          cnpj,
                          email,
                          codigoBanco,
                          tipoConta,
                          agencia,
                          conta,
                          contaDig,
                          corHexadecimal,
                          endereco,
                          usuario,
                        } = data;

                        const payload = {
                          nomeFantasia,
                          razaoSocial,
                          celular,
                          telefoneFixo,
                          cnpj,
                          email,
                          chave,
                          codigoBanco: +codigoBanco,
                          tipoConta,
                          agencia,
                          conta,
                          contaDig,
                          corHexadecimal,
                          usuario,
                          endereco,
                        };

                        const response = await cadastrarParceiro(payload).catch(
                          () => setLoading(false),
                        );

                        if (response?.sucesso) {
                          Modal({
                            text: 'Cadastro realizado com sucesso, em breve entraremos em contato. Equipe Voepay.',
                            icon: 'success',
                          });
                        }
                        setLoading(false);
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <h2 className="mb-10 font-weight-bolder">
                            Cadastro de Parceiro
                          </h2>

                          <div className="form-group">
                            <h4 className="mb-5 font-weight-bold text-primary">
                              <i className="far fa-edit text-primary"></i> Dados
                              Pessoais
                            </h4>
                          </div>
                          <div className="form-group">
                            <label>Nome Completo</label>
                            <Field
                              name="usuario.nome"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu nome"
                            />
                            <span className="form-text text-muted">
                              Por favor preencha seu nome
                            </span>
                            <ErrorMessage
                              name="usuario.nome"
                              component="small"
                            />
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>CPF</label>
                                <Field name="usuario.cpf">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="999.999.999-99"
                                      placeholder="Digite seu CPF"
                                    />
                                  )}
                                </Field>
                                <span className="form-text text-muted">
                                  Por favor preencha seu CPF
                                </span>
                                <ErrorMessage
                                  name="usuario.cpf"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Data de Nascimento</label>
                                <DatePicker
                                  selected={values?.usuario?.dataNascimento}
                                  onChange={(newDate) => {
                                    setFieldValue(
                                      'usuario.dataNascimento',
                                      newDate,
                                    );
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholderText="Selecione a data de nascimento"
                                  locale="pt-BR"
                                />
                                <span className="form-text text-muted">
                                  Por favor preencha sua Data de Nascimento
                                </span>
                                <ErrorMessage
                                  name="usuario.dataNascimento"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>E-mail</label>
                                <Field
                                  name="usuario.email"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite seu e-mail"
                                />

                                <span className="form-text text-muted">
                                  Por favor preencha seu e-mail
                                </span>
                                <ErrorMessage
                                  name="usuario.email"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Celular</label>
                                <Field name="usuario.celular">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="(99) 99999-9999"
                                      placeholder="Digite seu celular"
                                    />
                                  )}
                                </Field>

                                <span className="form-text text-muted">
                                  Por favor preencha seu celular
                                </span>
                                <ErrorMessage
                                  name="usuario.celular"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Telefone Fixo</label>
                                <Field name="usuario.telefoneFixo">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="(99) 9999-9999"
                                      placeholder="Digite seu telefone fixo"
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Telefone Comercial</label>
                                <Field name="usuario.telefoneComercial">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="(99) 9999-9999"
                                      placeholder="Digite seu telefone comercial"
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <h4 className="mb-5 font-weight-bold text-primary">
                              <i className="far fa-edit text-primary"></i> Dados
                              Comerciais
                            </h4>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Nome Fantasia</label>
                                <Field
                                  name="nomeFantasia"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite o nome fantasia"
                                />
                                <span className="form-text text-muted">
                                  Por favor preencha o nome fantasia
                                </span>
                                <ErrorMessage
                                  name="nomeFantasia"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Razão Social</label>
                                <Field
                                  name="razaoSocial"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite o nome fantasia"
                                />
                                <span className="form-text text-muted">
                                  Por favor preencha a razão social
                                </span>
                                <ErrorMessage
                                  name="razaoSocial"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Celular</label>
                                <Field name="celular">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="(99) 99999-9999"
                                      placeholder="Digite seu celular"
                                    />
                                  )}
                                </Field>

                                <span className="form-text text-muted">
                                  Por favor preencha o celular da companhia
                                </span>
                                <ErrorMessage
                                  name="celular"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Telefone Fixo</label>
                                <Field name="telefoneFixo">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="(99) 9999-9999"
                                      placeholder="Digite seu telefone fixo"
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>CNPJ</label>
                                <Field name="cnpj">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="99.999.999/9999-99"
                                      placeholder="Digite o CNPJ"
                                    />
                                  )}
                                </Field>
                                <span className="form-text text-muted">
                                  Por favor preencha o CNPJ
                                </span>
                                <ErrorMessage name="cnpj" component="small" />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>E-mail</label>
                                <Field name="email"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite e-mail"
                                />

                                <span className="form-text text-muted">
                                  Por favor preencha o e-mail
                                </span>
                                <ErrorMessage
                                  name="email"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <h4 className="mb-5 font-weight-bold text-primary">
                              <i className="far fa-edit text-primary"></i> Dados
                              Bancários
                            </h4>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Banco</label>
                                <Select
                                  name="codigoBanco"
                                  value={bancos.find(
                                    (banco) =>
                                      banco.value ===
                                      String(values?.parceiro?.codigoBanco),
                                  )}
                                  onChange={(e) =>
                                    setFieldValue('codigoBanco', e.value)
                                  }
                                  placeholder="Selecione o banco"
                                  options={bancos}
                                />

                                <span className="form-text text-muted">
                                  Por favor selecione o banco
                                </span>
                                <ErrorMessage
                                  name="codigoBanco"
                                  component="small"
                                />
                              </div>
                            </div>

                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Tipo de Conta</label>
                                <Select
                                  name="tipoConta"
                                  value={tiposConta.find(
                                    (tipoConta) =>
                                      tipoConta.value === values.tipoConta,
                                  )}
                                  onChange={(e) =>
                                    setFieldValue('tipoConta', e.value)
                                  }
                                  placeholder="Selecione o tipo de conta"
                                  options={tiposConta}
                                />
                                <span className="form-text text-muted">
                                  Por favor preencha o tipo de conta
                                </span>
                                <ErrorMessage
                                  name="tipoConta"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Agência</label>
                                <Field
                                  name="agencia"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a agência"
                                />
                                <span className="form-text text-muted">
                                  Por favor preencha a agência
                                </span>
                                <ErrorMessage
                                  name="agencia"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="row">
                                <div className="col-xl-8">
                                  <div className="form-group">
                                    <label>Conta</label>
                                    <Field
                                      name="conta"
                                      className="form-control form-control-solid form-control-lg"
                                      placeholder="Digite a conta"
                                    />
                                    <span className="form-text text-muted">
                                      Por favor preencha a conta
                                    </span>
                                    <ErrorMessage
                                      name="conta"
                                      component="small"
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-4">
                                  <div className="form-group">
                                    <label>Dígito</label>
                                    <Field
                                      name="contaDig"
                                      className="form-control form-control-solid form-control-lg"
                                      placeholder="0"
                                    />
                                    <ErrorMessage
                                      name="contaDig"
                                      component="small"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <h4 className="mb-5 font-weight-bold text-primary">
                              <i className="far fa-edit text-primary"></i>{' '}
                              Endereço
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Digite seu CEP</label>

                                <Field name="endereco.cep">
                                  {({ field, form }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control form-control-solid form-control-lg"
                                      mask="99999-999"
                                      placeholder="Digite seu CEP"
                                      onBlur={(e) => handleCep(e, form)}
                                    />
                                  )}
                                </Field>

                                <span className="form-text text-muted">
                                  Por favor preencha seu CEP
                                </span>
                                <ErrorMessage
                                  name="endereco.cep"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Bairro</label>
                                <Field
                                  name="endereco.bairro"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite seu bairro"
                                  disabled={values?.endereco?.cep}
                                />
                                <span className="form-text text-muted">
                                  Por favor preencha seu bairro
                                </span>
                                <ErrorMessage
                                  name="endereco.bairro"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Logradouro</label>
                            <Field
                              name="endereco.logradouro"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu logradouro"
                              disabled={values?.endereco?.cep}
                            />

                            <span className="form-text text-muted">
                              Por favor preencha seu logradouro
                            </span>
                            <ErrorMessage
                              name="endereco.logradouro"
                              component="small"
                            />
                          </div>
                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Estado</label>
                                <Field
                                  name="endereco.estado"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite seu estado"
                                  disabled={values?.endereco?.cep}
                                />

                                <span className="form-text text-muted">
                                  Por favor preencha seu estado
                                </span>
                                <ErrorMessage
                                  name="endereco.estado"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Cidade</label>
                                <Field
                                  name="endereco.cidade"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite sua cidade"
                                  disabled={values?.endereco?.cep}
                                />

                                <span className="form-text text-muted">
                                  Por favor preencha seu cidade
                                </span>
                                <ErrorMessage
                                  name="endereco.cidade"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Número</label>
                                <Field
                                  name="endereco.numero"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite o número"
                                />

                                <span className="form-text text-muted">
                                  Por favor preencha o número
                                </span>
                                <ErrorMessage
                                  name="endereco.numero"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Complemento</label>
                                <Field
                                  name="endereco.complemento"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite o complemento"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <h4 className="mb-5 font-weight-bold text-primary">
                              <i className="far fa-edit text-primary"></i>{' '}
                              Integração
                            </h4>
                          </div>

                          <div className="form-group">
                            <label>Chave</label>
                            <Field
                              name="chave"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Chave da companhia"
                              disabled
                              value={chave}
                            />
                          </div>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Cor Hexadecimal</label>
                                <Field
                                  name="corHexadecimal"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Selecione a cor"
                                  disabled
                                />

                                <span className="form-text text-muted">
                                  Por favor selecione a cor
                                </span>
                                <ErrorMessage
                                  name="corHexadecimal"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <label>&nbsp;</label>
                              <button
                                type="button"
                                onClick={() => handleColorPicker()}
                                className="form-control form-control-lg btn btn-primary font-weight-bolder text-uppercase"
                              >
                                Selecionar cor
                              </button>
                              {displayColorPicker && (
                                <div className="color-picker-popover">
                                  <div
                                    className="cover-color-picker"
                                    onClick={() => setDisplayColorPicker(false)}
                                  />
                                  <SketchPicker
                                    color={color}
                                    onChangeComplete={(newColor) =>
                                      handleChangeComplete(
                                        newColor,
                                        setFieldValue,
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-end border-top mt-5 pt-10">
                            <button
                              type="submit"
                              className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                            >
                              Cadastrar
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Card>
          </Overlay>
        </Container>
        <Footer />
      </>
    )
  );
}
