import { useState, useEffect } from 'react';

import { addMinutes } from 'date-fns';

import {
  autenticar,
  enviarCodigoSenha,
  validarCodigo,
  trocarSenha,
  getToken,
} from '../../webReferences/authAPI';

import { getConfig } from '../../webReferences/configAPI';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userData = localStorage.getItem('userData');

    if (userData) {
      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  async function handleLogin(cpf, password) {
    const userData = await autenticar(cpf, password);

    if (
      userData.sucesso &&
      userData.data.autenticado &&
      !userData.data.redirecionarTrocaDeSenha
    ) {
      const authToken = await getToken();

      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.setItem('authTokenData', JSON.stringify(authToken.data));

      const config = await getConfig();

      const { tempoSessaoAreaLogadaParceiro } = config.data;

      const dataExpiracao = addMinutes(
        new Date(),
        tempoSessaoAreaLogadaParceiro,
      );

      localStorage.setItem('dataExpiracao', dataExpiracao);

      setAuthenticated(true);
      window.location = '/home';
    }

    return userData;
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem('userData');
    window.location = '/login';
  }

  return {
    authenticated,
    loading,
    handleLogin,
    handleLogout,
    enviarCodigoSenha,
    validarCodigo,
    trocarSenha,
  };
}
