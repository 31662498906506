const initialValues = {
  nomeUsuario: '',
  email: '',
  celular: '',
  telefoneFixo: '',
  telefoneComercial: '',
  endereco: {
    cep: '',
    bairro: '',
    logradouro: '',
    estado: '',
    cidade: '',
    numero: '',
    complemento: '',
  },
  parceiro: {
    nomeFantasia: '',
    razaoSocial: '',
    celular: '',
    telefoneFixo: '',
    cnpj: '',
    chave: '',
    codigoBanco: '',
    tipoConta: '',
    agencia: '',
    agenciaDig: '',
    conta: '',
    contaDig: '',
  },
  senha: '',
  confirmarSenha: '',
};

export default initialValues;
