import { object, string, number, addMethod } from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

addMethod(string, 'validateCPF', function (errorMessage) {
  return this.test(`test-cpf`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      return cpf.isValid(value) || createError({ path, message: errorMessage });
    } else {
      return true;
    }
  });
});

addMethod(string, 'validateCNPJ', function (errorMessage) {
  return this.test(`test-cnpj`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      return (
        cnpj.isValid(value) || createError({ path, message: errorMessage })
      );
    } else {
      return true;
    }
  });
});

const validationSchema = object().shape({
  nomeFantasia: string().required('O nome fantasia é obrigatório').nullable(),
  razaoSocial: string().required('A razão social é obrigatória').nullable(),
  celular: string().required('O celular é obrigatório').nullable(),
  email: string()
  .email('E-mail inválido')
  .required('O email é obrigatório')
  .nullable(),
  cnpj: string()
    .validateCNPJ('CNPJ inválido')
    .required('O cnpj é obrigatório')
    .nullable(),
  codigoBanco: string().required('O banco é obrigatório').nullable(),
  tipoConta: string().required('O tipo de conta é obrigatório').nullable(),
  agencia: string().required('A agência é obrigatória').nullable(),
  conta: string().required('A conta obrigatória').nullable(),
  contaDig: string().required('O dígito da conta é obrigatório').nullable(),
  corHexadecimal: string()
    .required('A cor hexadecimal é obrigatória')
    .nullable(),
  usuario: object().shape({
    nome: string().required('O nome é obrigatório').nullable(),
    cpf: string()
      .validateCPF('CPF inválido')
      .required('O cpf é obrigatório')
      .nullable(),
    dataNascimento: string()
      .required('A data de nascimento é obrigatória')
      .nullable(),
    email: string()
      .email('E-mail inválido')
      .required('O email é obrigatório')
      .nullable(),
    celular: string().required('O celular é obrigatório').nullable(),
  }),
  endereco: object().shape({
    cep: string()
      .matches(/\d{5}-\d{3}/, 'CEP inválido')
      .required('O cep é obrigatório')
      .nullable(),
    bairro: string().required('O bairro é obrigatório').nullable(),
    logradouro: string().required('O logradouro é obrigatório').nullable(),
    estado: string().required('O estado é obrigatório').nullable(),
    cidade: string().required('A cidade é obrigatória').nullable(),
    numero: number().required('O número é obrigatório').nullable(),
  }),
});

export default validationSchema;
