import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Home from '../pages/Home';
import Perfil from '../pages/Perfil';
import Propostas from '../pages/Propostas';
import Proposta from '../pages/Proposta';
import Cadastro from '../pages/Cadastro';

import PrivateRoute from './privateRoute';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/cadastro" component={Cadastro} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/perfil" component={Perfil} />
      <PrivateRoute exact path="/propostas" component={Propostas} />
      <PrivateRoute exact path="/proposta/:propostaId" component={Proposta} />
    </Switch>
  );
}
