import { object, number } from 'yup';

const validationSchema = object().shape({
  tipoExtrato: number().required('Tipo extrato obrigatório').nullable(),
  tipoApresentacao: number()
    .required('Tipo apresentação obrigatório')
    .nullable(),
  formatoArquivo: number().required('Formato obrigatório').nullable(),
});

export default validationSchema;
