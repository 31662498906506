import React, { useState } from 'react';
import { format, subDays } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import { addDays, add } from 'date-fns';

import './styles.scss';

registerLocale('pt-BR', ptBR);

export default function DropdownFilter({ value, onValueChange, options }) {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const defaultOptions = options || [
    {
      value: `${format(new Date(), 'yyyy-MM-dd')} ${format(
        new Date(),
        'yyyy-MM-dd',
      )}`,
      label: 'Hoje',
    },
    {
      value: `${format(subDays(new Date(), 7), 'yyyy-MM-dd')} ${format(
        new Date(),
        'yyyy-MM-dd',
      )}`,
      label: '7 dias',
    },
    {
      value: `${format(subDays(new Date(), 15), 'yyyy-MM-dd')} ${format(
        new Date(),
        'yyyy-MM-dd',
      )}`,
      label: '15 dias',
    },
    {
      value: `${format(subDays(new Date(), 30), 'yyyy-MM-dd')} ${format(
        new Date(),
        'yyyy-MM-dd',
      )}`,
      label: '30 dias',
    },
    {
      value: `${format(subDays(new Date(), 90), 'yyyy-MM-dd')} ${format(
        new Date(),
        'yyyy-MM-dd',
      )}`,
      label: '90 dias',
    },
  ];

  const handleValueChange = (option) => {
    onValueChange(option);
    setShow(false);
  };

  const handleRangeDate = () => {
    onValueChange({
      label: `${format(startDate, 'dd/MM/yyyy')} até ${format(
        endDate,
        'dd/MM/yyyy',
      )}`,
      value: `${format(startDate, 'yyyy-MM-dd')} ${format(
        endDate,
        'yyyy-MM-dd',
      )}`,
    });
    setShow(false);
  };

  return (
    <div className="dropdownFilter">
      <button
        className="dropdownButton"
        onClick={() => setShow(!show)}
        type="button"
      >
        <i class="la la-filter icon-xl"></i>
        {value?.label} <i className="fas fa-sort-down"></i>
      </button>

      {show && (
        <div className="dropdownList">
          <div className="coverDropdown" onClick={() => setShow(false)} />
          <ul>
            {defaultOptions.map((option) => (
              <li onClick={() => handleValueChange(option)}>
                <i className="la la-calendar icon-lg"></i> {option.label}
              </li>
            ))}

            <hr />

            <li>
              <i className="la la-calendar icon-lg"></i> Outro período
            </li>
          </ul>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col">
                  <DatePicker
                    selected={startDate}
                    onChange={(newDate) => {
                      setStartDate(newDate);
                      setEndDate(null);
                    }}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="1º Data"
                    locale="pt-BR"
                    popperPlacement="top-end"
                  />
                </div>
                <div className="col">
                  <DatePicker
                    selected={endDate}
                    onChange={(newDate) => {
                      const dataFim = add(new Date(newDate), {
                        hours: 23,
                        minutes: 59,
                      });

                      setEndDate(dataFim);
                    }}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    startDate={startDate}
                    selectsEnd
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={addDays(new Date(startDate), 30)}
                    placeholderText="2º Data"
                    locale="pt-BR"
                    disabled={!startDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="dropdownButton"
                onClick={handleRangeDate}
                disabled={!startDate || !endDate}
                type="button"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
