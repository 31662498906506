import React, { useState } from 'react';

import DropdownFilter from '../elements/DropdownFilter';
import ModalExport from '../ModalExport';

import './styles.scss';

export default function CardAnteciped({ title }) {
  const [hide, setHide] = useState(true);
  const [period, setPeriod] = useState({ value: 'hoje', label: 'Hoje' });
  const [modalVisible, setModalVisible] = useState(false);

  const modalToggle = () => setModalVisible(!modalVisible);

  return (
    <div className="cardHome">
      <div className="card-home-header">
        <h5>
          <i className="fas fa-hand-holding-usd"></i> {title}
        </h5>
        <DropdownFilter
          value={period}
          onValueChange={(selectedPeriod) => setPeriod(selectedPeriod)}
        />
      </div>
      <hr />

      <div className="card-home-body">
        <div className="payments">
          <h6>Antecipações</h6>
          <i
            className="fas fa-eye hide-button"
            onClick={() => setHide(!hide)}
          ></i>
        </div>
        <div className="net-total">
          <span className="key">Total líquido antecipado</span>
          <span className={!hide ? 'value' : 'hide-value'}>
            {!hide && 'R$ 25567,63'}
          </span>
        </div>

        <div className="net-sale">
          <span className="key">Total Bruto</span>
          <span className={!hide ? 'value' : 'hide-value'}>
            {!hide && 'R$ 19852,63'}
          </span>
        </div>

        <div className="tariffsFees">
          <span className="key">Tarifas e taxas</span>
          <span className={!hide ? 'value' : 'hide-value'}>
            {!hide && 'R$ 5401,02'}
          </span>
        </div>

        <span className="obs">
          *Antecipações realizadas no dia serão apresentadas no dia seguinte.
        </span>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-success mt-3"
            onClick={modalToggle}
          >
            <i className="far fa-file"></i> Exportar
          </button>
        </div>

        <ModalExport modalVisible={modalVisible} modalToggle={modalToggle} />
      </div>
    </div>
  );
}
