import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';

import { getProposta } from '../../webReferences/propostasAPI';
import { currencyFormat } from '../../helpers/Utils';
import { useParams } from 'react-router-dom';

export default function Proposta() {
  const params = useParams();
  const propostaId = params?.propostaId;
  const [proposta, setProposta] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadProposta() {
      setLoading(true);
      const data = await getProposta(propostaId);
      setProposta(data);
      setLoading(false);
    }

    if (propostaId) {
      loadProposta();
    }
  }, [propostaId]);

  const nomeCompleto = () =>
    proposta && `${proposta?.cliente.nome} ${proposta?.cliente.sobrenome}`;

  const endereco = () => {
    const enderecoCliente = proposta?.cliente?.endereco?.[0];
    const { logradouro, numero, bairro, complemento, cep, cidade, estado } =
      enderecoCliente;

    return (
      <>
        {logradouro} {numero}, {bairro} - {complemento}
        <br />
        {cep} - {cidade} - {estado}
      </>
    );
  };

  return (
    <Container>
      <Overlay active={loading}>
        <Card>
          <div className="card-body p-0">
            <div className="row justify-content-center py-8 px-8 py-md-15 px-md-0">
              <div className="col-md-9">
                <div className="d-flex justify-content-between pb-10 pb-md-5 flex-column flex-md-row">
                  <h1 className="display-4 font-weight-boldest mb-10">
                    {nomeCompleto()}
                  </h1>
                  <div className="d-flex flex-column align-items-md-end px-0">
                    <span className="d-flex flex-column align-items-md-end opacity-70">
                      <span>{proposta && endereco()}</span>
                    </span>
                  </div>
                </div>
                <div className="border-bottom w-100"></div>
                <div className="d-flex justify-content-between pt-6">
                  <div className="d-flex flex-column flex-root">
                    <span className="font-weight-bolder mb-2">
                      Data da contratação
                    </span>
                    <span className="opacity-70">
                      {proposta?.dataProposta &&
                        format(new Date(proposta?.dataProposta), 'dd/MM/yyyy')}
                    </span>
                  </div>
                  <div className="d-flex flex-column flex-root">
                    <span className="font-weight-bolder mb-2">
                      Cod. da Proposta
                    </span>
                    <span className="opacity-70">
                      {proposta?.codigoProposta}
                    </span>
                  </div>
                  <div className="d-flex flex-column flex-root">
                    <span className="font-weight-bolder mb-2">
                      Informações da Viagem
                    </span>
                    <span className="opacity-70">
                      {proposta?.viagemTrecho[0]?.dataPartida &&
                        `Data Viagem: ${format(
                          new Date(proposta?.viagemTrecho[0]?.dataPartida),
                          'dd/MM/yyyy',
                        )}`}

                      <br />
                      {proposta?.pessoa?.length &&
                        `Nº de Passageiros: ${proposta?.pessoa?.length} `}
                      <br />

                      {proposta?.viagemTrecho[0]?.destino &&
                        `Destino: ${proposta?.viagemTrecho[0]?.destino} `}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
              <div className="col-md-9">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="pl-0 font-weight-bold text-muted text-uppercase">
                          Plano Contratado
                        </th>
                        <th className="text-right font-weight-bold text-muted text-uppercase">
                          Valor da Viagem
                        </th>
                        <th className="text-right font-weight-bold text-muted text-uppercase">
                          Valor da Parcela
                        </th>
                        <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">
                          Prazo Contratado
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="font-weight-boldest">
                        <td className="pl-0 pt-7">{proposta?.nomePlano}</td>
                        <td className="text-right pt-7">
                          {proposta?.valorProposta &&
                            currencyFormat(proposta?.valorProposta)}
                        </td>
                        <td className="text-right pt-7">
                          {proposta?.valorParcela &&
                            currencyFormat(proposta?.valorParcela)}
                        </td>
                        <td className="text-right pr-0 pt-7 text-right">
                          {proposta?.quantidadeParcelas &&
                            `${proposta?.quantidadeParcelas} meses`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
              <div className="col-md-9">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={() => window.print()}
                  >
                    Imprimir Proposta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Overlay>
    </Container>
  );
}
