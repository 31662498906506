import React from 'react';

import CardReceived from '../../components/CardReceived';
import CardReceive from '../../components/CardReceive';
import CardAnteciped from '../../components/CardAnteciped';
import CardPropostas from '../../components/CardPropostas';

export default function Home() {
  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 mt-3">
            <CardPropostas title="Propostas" />
          </div>
          <div className="col-xl-6 mt-3">
            <CardReceive title="O que vou receber" />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 mt-8">
            <CardReceived title="O que recebi" />
          </div>
          <div className="col-xl-6 mt-8">
            <CardAnteciped title="O que antecipei" />
          </div>
        </div>
      </div>
    </div>
  );
}
