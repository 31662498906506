import React from 'react';
import logoDark from './img/logo-dark.png';

export default function Footer() {
  return (
    <footer className="bg-dark text-inverse">
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-4 col-lg-4">
            <div className="widget">
              <img
                className="mb-4"
                src={logoDark}
                srcSet={logoDark}
                alt=""
                style={{ width: 45 }}
              />
              <p className="mb-4">
                © 2021 VoePay. <br className="d-none d-lg-block" />
                Todos os direitos reservados.
                <br /> Powered by RZ8 Web
              </p>

              {/* <!-- /.social --> */}
            </div>
            {/* <!-- /.widget --> */}
          </div>
          {/* <!-- /column --> */}
          <div className="col-md-4 col-lg-4">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Fale Conosco</h4>
              <address className="pe-xl-15 pe-xxl-17">
                Moonshine St. 14/05 Light City, London, United Kingdom
              </address>
              <a href="mailto:#">info@email.com</a>
              <br /> +00 (123) 456 78 90
            </div>
            {/* <!-- /.widget --> */}
          </div>
          {/* <!-- /column --> */}
          <div className="col-md-4 col-lg-4">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Menu</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#">A Voe Pay</a>
                </li>
                <li>
                  <a href="#">Condições Exclusivas</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">Fale Conosco</a>
                </li>
                <li>
                  <a href="#">Política de Privacidade</a>
                </li>
                <li>
                  <a href="#">Termo de Uso</a>
                </li>
              </ul>
            </div>
            {/* <!-- /.widget --> */}
          </div>
          {/* <!-- /column --> */}

          {/* <!-- /column --> */}
        </div>
        {/* <!--/.row --> */}
      </div>
      {/* <!-- /.container --> */}
    </footer>
  );
}
