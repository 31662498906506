import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import cep from 'cep-promise';
import Select from 'react-select';
import { SketchPicker } from 'react-color';

import { getUsuario, atualizarUsuario } from '../../webReferences/userAPI';
import { getBancos } from '../../webReferences/bancoAPI';

import InputMask from 'react-input-mask';

import validationSchema from './validationSchema';
import initialValues from './initialValues';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';

import './styles.scss';

const tiposConta = [
  {
    label: 'Conta Corrente',
    value: 1,
  },
  {
    label: 'Poupança',
    value: 2,
  },
];

export default function Perfil() {
  const [formData, setFormData] = useState(initialValues);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { usuarioId } = userData.data;
  const [loading, setLoading] = useState(false);
  const [bancos, setBancos] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('');

  useEffect(() => {
    async function loadUsuario() {
      setLoading(true);
      const ret = await getUsuario(usuarioId);

      setFormData((formData) => ({
        ...formData,
        ...(ret.data || initialValues),
      }));
      setLoading(false);
    }

    async function loadBancos() {
      const ret = await getBancos();
      setBancos(
        ret?.data?.map((banco) => ({
          label: banco.descricao,
          value: banco.codigoFebraBan,
        })) || [],
      );
    }

    loadUsuario();
    loadBancos();
  }, [usuarioId]);

  const handleCep = (e, form) => {
    const { setFieldTouched, setValues, setFieldError, values } = form;

    setFieldTouched('endereco.cep', true);

    let { value } = e.target;
    value = value.replace(/\D/g, '');

    cep(value)
      .then((cepData) => {
        setValues({
          ...values,
          endereco: {
            ...values.endereco,
            bairro: cepData.neighborhood,
            logradouro: cepData.street,
            estado: cepData.state,
            cidade: cepData.city,
          },
        });
      })
      .catch(() => {
        setFieldError('endereco.cep', 'CEP não encontrado');

        setValues({
          ...values,
          endereco: {
            ...values.endereco,
            bairro: '',
            logradouro: '',
            estado: '',
            cidade: '',
          },
        });
      });
  };

  const handleColorPicker = () => setDisplayColorPicker(!displayColorPicker);

  const handleChangeComplete = (color, setFieldValue) => {
    setColor(color.hex);
    setFieldValue('parceiro.corHexadecimal', color.hex);
  };

  return (
    <Container>
      <Overlay active={loading} marginTop={300}>
        <Card>
          <div className="card-body p-0">
            <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div className="col-xl-12 col-xxl-7">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  enableReinitialize={true}
                  onSubmit={async (data) => {
                    setLoading(true);

                    const {
                      usuarioId,
                      tipoUsuarioId,
                      nomeUsuario,
                      email,
                      celular,
                      telefoneFixo,
                      telefoneComercial,
                      endereco,
                      senha,
                    } = data;

                    const parceiro = {
                      ...data.parceiro,
                      codigoBanco: +data.parceiro.codigoBanco,
                    };

                    const payload = {
                      nomeUsuario,
                      email,
                      celular,
                      telefoneFixo,
                      telefoneComercial,
                      endereco,
                      parceiro,
                      senha,
                      usuarioId,
                      tipoUsuarioId,
                    };

                    const response = await atualizarUsuario(payload).catch(() =>
                      setLoading(false),
                    );

                    if (response?.sucesso) {
                      setLoading(false);

                      localStorage.setItem(
                        'userData',
                        JSON.stringify({
                          ...userData,
                          data: {
                            ...userData.data,
                            corHexadecimal: parceiro.corHexadecimal,
                          },
                        }),
                      );

                      Modal({
                        text: 'Perfil atualizado com sucesso!',
                        icon: 'success',
                      });
                    }
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <h2 className="mb-10 font-weight-bolder">
                        Edição de dados
                      </h2>
                      <div className="form-group">
                        <h4 className="mb-5 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Dados
                          Pessoais
                        </h4>
                      </div>
                      <div className="form-group">
                        <label>Nome Completo</label>
                        <Field
                          name="nomeUsuario"
                          className="form-control form-control-solid form-control-lg"
                          placeholder="Digite seu nome"
                        />
                        <span className="form-text text-muted">
                          Por favor preencha seu nome
                        </span>
                        <ErrorMessage name="nomeUsuario" component="small" />
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>E-mail</label>
                            <Field
                              name="email"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu e-mail"
                            />

                            <span className="form-text text-muted">
                              Por favor preencha seu e-mail
                            </span>
                            <ErrorMessage name="email" component="small" />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Celular</label>
                            <Field name="celular">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 99999-9999"
                                  placeholder="Digite seu celular"
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Por favor preencha seu celular
                            </span>
                            <ErrorMessage name="celular" component="small" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Telefone Fixo</label>
                            <Field name="telefoneFixo">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 9999-9999"
                                  placeholder="Digite seu telefone fixo"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Telefone Comercial</label>
                            <Field name="telefoneComercial">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 9999-9999"
                                  placeholder="Digite seu telefone comercial"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <h4 className="mb-5 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Dados
                          Comerciais
                        </h4>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Nome Fantasia</label>
                            <Field
                              name="parceiro.nomeFantasia"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite o nome fantasia"
                            />
                            <span className="form-text text-muted">
                              Por favor preencha o nome fantasia
                            </span>
                            <ErrorMessage
                              name="parceiro.nomeFantasia"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Razão Social</label>
                            <Field
                              name="parceiro.razaoSocial"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite o nome fantasia"
                            />
                            <span className="form-text text-muted">
                              Por favor preencha a razão social
                            </span>
                            <ErrorMessage
                              name="parceiro.razaoSocial"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Celular</label>
                            <Field name="parceiro.celular">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 99999-9999"
                                  placeholder="Digite seu celular"
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Por favor preencha o celular da companhia
                            </span>
                            <ErrorMessage
                              name="parceiro.celular"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Telefone Fixo</label>
                            <Field name="parceiro.telefoneFixo">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 9999-9999"
                                  placeholder="Digite seu telefone fixo"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>CNPJ</label>
                            <Field
                              name="parceiro.cnpj"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="CNPJ da companhia"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <h4 className="mb-5 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Dados
                          Bancários
                        </h4>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Banco</label>
                            <Select
                              name="parceiro.codigoBanco"
                              value={bancos.find(
                                (banco) =>
                                  banco.value ===
                                  String(values?.parceiro?.codigoBanco),
                              )}
                              onChange={(e) =>
                                setFieldValue('parceiro.codigoBanco', e.value)
                              }
                              placeholder="Selecione o banco"
                              options={bancos}
                            />

                            <span className="form-text text-muted">
                              Por favor selecione o banco
                            </span>
                            <ErrorMessage
                              name="parceiro.codigoBanco"
                              component="small"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Tipo de Conta</label>
                            <Select
                              name="parceiro.tipoConta"
                              value={tiposConta.find(
                                (tipoConta) =>
                                  tipoConta.value === values.parceiro.tipoConta,
                              )}
                              onChange={(e) =>
                                setFieldValue('parceiro.tipoConta', e.value)
                              }
                              placeholder="Selecione o tipo de conta"
                              options={tiposConta}
                            />
                            <span className="form-text text-muted">
                              Por favor preencha o tipo de conta
                            </span>
                            <ErrorMessage
                              name="parceiro.tipoConta"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Agência</label>
                            <Field
                              name="parceiro.agencia"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite a agência"
                            />
                            <span className="form-text text-muted">
                              Por favor preencha a agência
                            </span>
                            <ErrorMessage
                              name="parceiro.agencia"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="row">
                            <div className="col-xl-8">
                              <div className="form-group">
                                <label>Conta</label>
                                <Field
                                  name="parceiro.conta"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a conta"
                                />
                                <span className="form-text text-muted">
                                  Por favor preencha a conta
                                </span>
                                <ErrorMessage
                                  name="parceiro.conta"
                                  component="small"
                                />
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="form-group">
                                <label>Dígito</label>
                                <Field
                                  name="parceiro.contaDig"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="0"
                                />
                                <ErrorMessage
                                  name="parceiro.contaDig"
                                  component="small"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <h4 className="mb-5 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Endereço
                        </h4>
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Digite seu CEP</label>

                            <Field name="endereco.cep">
                              {({ field, form }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="99999-999"
                                  placeholder="Digite seu CEP"
                                  onBlur={(e) => handleCep(e, form)}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Por favor preencha seu CEP
                            </span>
                            <ErrorMessage
                              name="endereco.cep"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Bairro</label>
                            <Field
                              name="endereco.bairro"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu bairro"
                              disabled={values?.endereco?.cep}
                            />
                            <span className="form-text text-muted">
                              Por favor preencha seu bairro
                            </span>
                            <ErrorMessage
                              name="endereco.bairro"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Logradouro</label>
                        <Field
                          name="endereco.logradouro"
                          className="form-control form-control-solid form-control-lg"
                          placeholder="Digite seu logradouro"
                          disabled={values?.endereco?.cep}
                        />

                        <span className="form-text text-muted">
                          Por favor preencha seu logradouro
                        </span>
                        <ErrorMessage
                          name="endereco.logradouro"
                          component="small"
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Estado</label>
                            <Field
                              name="endereco.estado"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu estado"
                              disabled={values?.endereco?.cep}
                            />

                            <span className="form-text text-muted">
                              Por favor preencha seu estado
                            </span>
                            <ErrorMessage
                              name="endereco.estado"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Cidade</label>
                            <Field
                              name="endereco.cidade"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite sua cidade"
                              disabled={values?.endereco?.cep}
                            />

                            <span className="form-text text-muted">
                              Por favor preencha seu cidade
                            </span>
                            <ErrorMessage
                              name="endereco.cidade"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Número</label>
                            <Field
                              name="endereco.numero"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite o número"
                            />

                            <span className="form-text text-muted">
                              Por favor preencha o número
                            </span>
                            <ErrorMessage
                              name="endereco.numero"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Complemento</label>
                            <Field
                              name="endereco.complemento"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite o complemento"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <h4 className="mb-5 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i>{' '}
                          Integração
                        </h4>
                      </div>

                      <div className="form-group">
                        <label>Chave</label>
                        <Field
                          name="parceiro.chave"
                          className="form-control form-control-solid form-control-lg"
                          placeholder="Chave da companhia"
                          disabled
                        />
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Cor Hexadecimal</label>
                            <Field
                              name="parceiro.corHexadecimal"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Selecione a cor"
                              disabled
                            />

                            <span className="form-text text-muted">
                              Por favor selecione a cor
                            </span>
                            <ErrorMessage
                              name="parceiro.corHexadecimal"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <label>&nbsp;</label>
                          <button
                            type="button"
                            onClick={() => handleColorPicker()}
                            className="form-control form-control-lg btn btn-primary font-weight-bolder text-uppercase"
                          >
                            Selecionar cor
                          </button>
                          {displayColorPicker && (
                            <div className="color-picker-popover">
                              <div
                                className="cover-color-picker"
                                onClick={() => setDisplayColorPicker(false)}
                              />
                              <SketchPicker
                                color={color}
                                onChangeComplete={(newColor) =>
                                  handleChangeComplete(newColor, setFieldValue)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <h4 className="mb-5 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Alterar
                          Senha
                        </h4>
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Nova Senha</label>
                            <Field
                              name="senha"
                              type="password"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite sua nova senha"
                            />
                            <ErrorMessage name="senha" component="small" />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Redigir Senha</label>
                            <Field
                              name="confirmarSenha"
                              type="password"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Confirme sua nova senha"
                            />
                            <ErrorMessage
                              name="confirmarSenha"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end border-top mt-5 pt-10">
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                        >
                          Salvar
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Card>
      </Overlay>
    </Container>
  );
}
