/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';

import './styles.scss';

export default function Menu() {
  return (
    <div className="header-bottom">
      <div className="container">
        <div className="header-navs header-navs-left" id="kt_header_navs">
          <div className="tab-content">
            <div
              className="tab-pane py-5 p-lg-0 show active"
              id="kt_header_tab_1"
            >
              <div
                id="kt_header_menu"
                className="header-menu header-menu-mobile header-menu-layout-default"
              >
                <ul className="menu-nav">
                  <li className="menu-item">
                    <NavLink
                      to="/home"
                      className="menu-link"
                      aria-haspopup="true"
                      activeClassName="menu-active"
                    >
                      <span className="menu-text">Dashboard</span>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="/propostas"
                      className="menu-link"
                      aria-haspopup="true"
                      activeClassName="menu-active"
                    >
                      <span className="menu-text">Propostas</span>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link">
                      <span className="menu-text">Relatórios</span>
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link">
                      <span className="menu-text">Usuários</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
